<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Galeri : {{ rows }}</h3>
    </b-card-body>
    <!-- fun filter(search) by title and type gallery -->
    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- gallery title filter -->
          <b-form-group label="Gallery Title" label-for="gallery_title">
            <validation-provider #default="{ errors }" name="gallery_title">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="gallery_title"
                  placeholder="Search Gallery Title..."
                  v-model="filter.gallery_title"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- gallery type filter -->
          <b-form-group label="Gallery Type" label-for="gallery_type">
            <validation-provider #default="{ errors }" name="gallery_type">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <v-select
                  id="gallery_type"
                  v-model="filter.gallery_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="option"
                  placeholder="Search Gallery Type.."
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="3" class="mb-25">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="refreshPage()"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col lg="20" md="12" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-2 mt-md-0"
            @click="modalAddGaleri()"
          >
            <feather-icon icon="PlusIcon" class="mr-26" />
            <span>Tambah Data Galeri</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="showDetailGaleri(data.item)">
            <feather-icon icon="SettingsIcon" size="16" />
            <span class="align-middle ml-50">Detail</span>
          </b-dropdown-item>

          <b-dropdown-item @click="modalUpdateGaleri(data.item)">
            <feather-icon icon="CheckSquareIcon" size="16" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteDataGallery(data.item)">
            <feather-icon icon="XSquareIcon" size="16" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    <b-modal id="modal-export" hide-footer>
      <template #modal-title> Input Email Tujuan </template>
      <div class="d-block text-center">
        <p>
          Data yang akan diexport lebih dari <b>500</b> baris dan akan dikirim
          via email, Silahkan masukkan email anda!
        </p>
      </div>
      <b-form-group>
        <label for="email">Email:</label>
        <b-form-input
          id="email"
          type="email"
          placeholder="Email Address"
          v-model="email"
        />
      </b-form-group>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="exportData()"
      >
        <feather-icon icon="DownloadCloudIcon" /> Export
      </b-button>
    </b-modal>

    <b-modal id="modal-detail" size="xl" hide-footer>
      <template #modal-title> Detail Galeri </template>
      <!-- Invoice Description: Total -->
      <table style="width: 100%">
        <tr
          v-if="
            detailGaleri.gallery_type != 'video' &&
              detailGaleri.gallery_type != 'Video'
          "
        >
          <td>
            <center>
              <img
                :src="
                  'https://idijakpus.sgp1.digitaloceanspaces.com/files/' +
                    detailGaleri.gallery_file
                "
                alt="gallery_image"
                width="600"
                height="500"
              />
            </center>
          </td>
        </tr>
        <tr v-else>
          <td>
            <video
              width="1400"
              height="500"
              controls
              :src="
                'https://idijakpus.sgp1.digitaloceanspaces.com/files/' +
                  detailGaleri.gallery_file
              "
            />
          </td>
        </tr>
      </table>
    </b-modal>

    <!-- add galeri -->
    <b-modal
      id="modal-add-galeri"
      title="Tambah Galeri"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <validation-observer ref="validateBeforeAddGaleri">
        <b-form @submit.prevent>
          <b-form-group label="Galeri Title" label-for="gallery_title ">
            <validation-provider
              #default="{ errors }"
              name="Galeri Title"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="gallery_title"
                  v-model="gallery_title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Insert Gallery Title...."
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Galeri Type" label-for="gallery_type">
            <validation-provider
              #default="{ errors }"
              name="Galeri Type"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <v-select
                  id="gallery_type"
                  v-model="gallery_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="option"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Insert Gallery Type.."
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Galeri File" label-for="gallery_file">
            <validation-provider
              #default="{ errors }"
              name="Galeri File"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-file
                  id="gallery_file"
                  multiple
                  v-model="gallery_file"
                  :state="errors.length > 0 ? false : null"
                  :file-name-formatter="formatNames"
                  @change="handlerGaleriFile($event)"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <br />
          <b-button
            variant="primary"
            type="submit"
            block
            :disabled="invalid"
            @click="validateAddGaleri"
          >
            Add Galeri
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- update galeri -->
    <b-modal
      id="modal-update-galeri"
      title="Edit Galeri"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <validation-observer ref="validateBeforeUpdateGaleri">
        <b-form @submit.prevent>
          <b-form-input
            id="id_galeri"
            v-model="updateGaleri.id"
            placeholder="Judul Galeri"
            hidden
          />
          <p>
            <b>File Gallery: </b>
            <a :href="this.updateGaleri.gallery_file" target="_blank">Show</a>
          </p>
          <br />
          <b-form-group label="Galeri Title" label-for="gallery_title ">
            <validation-provider
              #default="{ errors }"
              name="Galeri Title"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="gallery_title"
                  v-model="updateGaleri.gallery_title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Insert Gallery Tittle..."
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Galeri Type" label-for="gallery_type">
            <validation-provider
              #default="{ errors }"
              name="Galeri Title"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <v-select
                  id="gallery_type"
                  v-model="updateGaleri.gallery_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :state="errors.length > 0 ? false : null"
                  :options="option"
                  placeholder="Insert Gallery Type.."
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Galeri File" label-for="gallery_file">
            <validation-provider
              #default="{ errors }"
              name="Galeri File"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-file
                  id="gallery_file"
                  multiple
                  v-model="updateGaleri.gallery_file"
                  :state="errors.length > 0 ? false : null"
                  :file-name-formatter="formatNames"
                  @change="handlerGaleriFile($event)"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <br />
          <b-button
            variant="primary"
            type="submit"
            block
            :disabled="invalid"
            @click="validateUpdateGaleri"
          >
            Update Galeri
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import {
  BTable,
  BButton,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BFormFile,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCol,
  BModal,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BTable,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ["multi", "single", "range"],
      fields: [
        { key: "id", label: "Id" },
        "gallery_title",
        "gallery_type",
        { key: "gallery_file", label: "link" },
        "created_at",
        "action",
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      selected: [],
      galeriFileUpload: "",
      search: null,
      detailPendidikan: {},
      detailGaleri: {},
      updateGaleri: {},
      base64Data: "",
      option: ["video", "image"],
      filter: {
        gallery_title: "",
        gallery_type: "",
      },
    };
  },
  methods: {
    validateAddGaleri() {
      this.$refs.validateBeforeAddGaleri.validate().then((success) => {
        if (success) {
          this.saveDataGallery();
        }
      });
    },
    validateUpdateGaleri() {
      this.$refs.validateBeforeUpdateGaleri.validate().then((success) => {
        if (success) {
          this.updateDataGallery();
        }
      });
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getGaleri();
      } else {
        this.currentPage = 1;
      }
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      }
      return `${files.length} files selected`;
    },
    showDetailGaleri(item) {
      this.detailGaleri = item;
      console.log(this.detailGaleri);
      this.$bvModal.show("modal-detail");
    },

    modalAddGaleri() {
      this.$bvModal.show("modal-add-galeri");
    },

    modalUpdateGaleri(item) {
      this.updateGaleri = item;
      this.$bvModal.show("modal-update-galeri");
    },
    handlerGaleriFile(e) {
      const { files } = e.target;
      if (files.length) {
        this.createHandlerGaleriFile(files[0], (result) => {
          this.galeriFileUpload = result;
        });
      }
    },
    createHandlerGaleriFile(file, cb) {
      const reader = new FileReader();
      reader.onload = (e) => {
        cb(e.target.result);
      };
      reader.readAsDataURL(file);
    },
    async getGaleri() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          ...this.filter,
        };
        const { data } = await API.galeri.list(form);
        this.data_table = data;
        this.items = data.data;
        this.rows = data.total;

        this.handleMsgSuccess("Berhasil tampilkan data galeri");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
    async saveDataGallery() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        let dataGaleri = {
          gallery_title: this.gallery_title,
          gallery_type: this.gallery_type,
          gallery_file: this.galeriFileUpload,
        };
        await API.galeri.addData(dataGaleri);
        this.refreshPage();
        this.handleMsgSuccess("Berhasil tambah galeri");
      } catch (error) {
        this.handleMsgErrors(error, "kesalahan dalam sistem harap cek kembali");
      } finally {
        this.$bvModal.hide("modal-add-galeri");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
    async updateDataGallery() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        let body = {
          gallery_title: this.updateGaleri.gallery_title,
          gallery_type: this.updateGaleri.gallery_type,
          gallery_file:
            this.galeriFileUpload != ""
              ? this.galeriFileUpload
              : this.updateGaleri.gallery_file,
        };
        await API.galeri.updateData(this.updateGaleri.id, body);
        this.refreshPage();
        this.handleMsgSuccess("Berhasil Update Galeri");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "kesalahan dalam sistem harap cek kembali");
      } finally {
        this.$bvModal.hide("modal-update-galeri");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
    async deleteDataGallery(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        this.$swal({
          title: "Apakah kamu yakin?",
          text: "Data galeri yang sudah dihapus, tidak bisa dikembalikan",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, hapus!",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then((result) => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
            if (result.value) {
              return API.galeri.deleteData(ids.id);
            }
            return false;
          })
          .then((result) => {
            if (result) {
              this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: "Berhasil menghapus data galeri",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
            this.refreshPage();
          })
          .catch((error) => {
            this.handleMsgErrors(error, "kesalahan update coba ulangi lagi");
          });
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "gagal delete galeri");
      } finally {
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },
  mounted() {
    this.getGaleri();
  },
  watch: {
    currentPage() {
      this.getGaleri();
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
